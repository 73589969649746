import { GetState, SetState, StateCreator, StoreApi } from "zustand"

export const isRunningInBrowser = () => typeof window !== "undefined"

const persist = <T extends Record<string, unknown>>(
	name: string,
	config: StateCreator<T>
) => (set: SetState<T>, get: GetState<T>, api: StoreApi<T>): T => {
	const state = config(
		(payload) => {
			set(payload)

			if (isRunningInBrowser()) {
				localStorage.setItem(name, JSON.stringify(payload))
			}
		},
		get,
		api
	)

	return {
		...state,
		...(isRunningInBrowser() && JSON.parse(localStorage.getItem(name) ?? "")),
	}
}

export default persist
