import Link from "next/link"
import { forwardRef, HTMLProps } from "react"
interface AnchorProps extends HTMLProps<HTMLAnchorElement> {
	className?: string
	href: string
}

const Anchor = forwardRef<HTMLAnchorElement, AnchorProps>(
	({ className, href, children, ...props }, ref) => {
		const Element = href.includes("http") ? "a" : Link

		return (
			<Element
				className={`hover:text-action transition-colors cursor-pointer ${className}`}
				href={href}
				// @ts-ignore
				ref={ref}
				{...props}
			>
				{children}
			</Element>
		)
	}
)

Anchor.displayName = "Anchor"
export default Anchor
