import { NextPageContext } from "next"
import { Cookies } from "react-cookie"
import { create } from "zustand"
import persistCookie from "../utils/persistCookies"

export interface AuthData extends Record<string, unknown> {
	token: string | undefined
	expiresIn: number | undefined
	refreshToken: string | undefined
	refreshExpiresIn: number | undefined
}

interface AuthStore extends Record<string, unknown> {
	token?: string
	expiresIn?: number
	refreshToken?: string
	refreshExpiresIn?: number

	update: (data: AuthData) => void
	remove: () => void
}

export const useAuthStore = create(
	persistCookie<AuthStore>((set, _get) => {
		return {
			token: undefined,
			expiresIn: undefined,
			refreshToken: undefined,
			refreshExpiresIn: undefined,

			update(data: AuthData) {
				set({
					token: data.token,
					expiresIn: data.expiresIn,
					refreshToken: data.refreshToken,
					refreshExpiresIn: data.refreshExpiresIn,
				})
			},

			remove() {
				set({
					token: undefined,
					expiresIn: undefined,
					refreshToken: undefined,
					refreshExpiresIn: undefined,
				})
			},
		}
	})
)

export const authFromContext = (context: NextPageContext): AuthData => {
	if (!context.req) {
		return {
			token: undefined,
			expiresIn: undefined,
			refreshToken: undefined,
			refreshExpiresIn: undefined,
		}
	}

	const cookies = new Cookies(context.req.headers.cookie)

	return {
		token: cookies.get("token"),
		expiresIn: cookies.get("expiresIn"),
		refreshToken: cookies.get("refreshToken"),
		refreshExpiresIn: cookies.get("refreshExpiresIn"),
	}
}

export const authFromStore = () => {
	const { token, expiresIn, refreshToken, refreshExpiresIn } =
		useAuthStore.getState()

	return {
		token,
		expiresIn,
		refreshToken,
		refreshExpiresIn,
	}
}
