import styled from "styled-components"

export const Container = styled.header`
	top: 0;
	z-index: 100;
	position: sticky;

	display: flex;
	align-items: center;
	justify-content: center;

	@media (max-width: 1024px) {
		padding: 0 20px;
		width: initial;
		align-self: stretch;
	}
`

export const Inner = styled.div`
	width: 1024px;
	align-self: center;

	display: flex;
	align-items: center;
	justify-content: space-between;
`
