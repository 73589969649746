import React from "react"
import Anchor from "./anchor/anchor"

interface SocialMediaGroupProps {
	linkedIn?: string
	instagram?: string
	twitter?: string
}
const SocialMediaGroup = ({
	linkedIn,
	instagram,
	twitter,
}: SocialMediaGroupProps) => {
	return (
		<div className="flex flex-row space-x-2 justify-center">
			{twitter && (
				<Anchor href={twitter} target="_blank" rel="noreferrer" alt="Twitter">
					<svg
						className="h-5 w-5"
						viewBox="0 0 16 14"
						fill="currentColor"
						xmlns="http://www.w3.org/2000/svg"
					>
						<title>Twitter</title>
						<path d="M15.4773 0.966943C15.464 0.766371 15.3319 0.593264 15.142 0.527519C14.952 0.461775 14.7412 0.516177 14.6067 0.665616C14.2611 1.04977 13.8243 1.31009 13.381 1.42878C12.7644 0.853153 11.936 0.50002 11.0256 0.50002C9.07181 0.50002 7.48923 2.08615 7.48923 4.04112V4.48092C4.49799 4.24976 2.89838 1.97748 2.46675 0.824696C2.39698 0.63836 2.22337 0.511123 2.02468 0.500706C1.82598 0.490288 1.64002 0.598671 1.55114 0.776689C0.504418 2.87338 0.343142 4.90696 1.1039 6.63863C1.77032 8.15556 3.11055 9.36392 5.01008 10.1645C4.14679 10.7763 2.69379 11.5 1.00004 11.5C0.779771 11.5 0.58545 11.6442 0.521544 11.855C0.457639 12.0658 0.539205 12.2935 0.722392 12.4158C2.3465 13.5003 4.04865 13.5001 5.63184 13.5L5.6803 13.5C10.541 13.5 14.48 9.5538 14.48 4.68751V4.20922C14.8434 3.83984 15.1264 3.46173 15.2998 2.9729C15.4932 2.42746 15.5322 1.795 15.4773 0.966943Z" />
					</svg>
				</Anchor>
			)}
			{instagram && (
				<Anchor
					href={instagram}
					target="_blank"
					rel="noreferrer"
					alt="Instagram"
				>
					<svg
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						className="h-5 w-5"
						viewBox="0 0 30 30"
					>
						<title>Instagram</title>
						<rect
							x="1.5"
							y="1.5"
							width="27"
							height="27"
							rx="7.5"
							stroke="currentColor"
							strokeWidth="3"
						/>
						<circle
							cx="15"
							cy="15"
							r="6.5"
							stroke="currentColor"
							strokeWidth="3"
						/>
						<circle cx="23" cy="7" r="2" fill="currentColor" />
					</svg>
				</Anchor>
			)}
			{linkedIn && (
				<Anchor href={linkedIn} target="_blank" rel="noreferrer" alt="LinkedIn">
					<svg
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						className="h-5 w-5"
						viewBox="0 0 30 30"
					>
						<title>LinkedIn</title>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M1.206 4.5C0 6.588 0 9.392 0 15s0 8.412 1.206 10.5A9 9 0 004.5 28.794C6.588 30 9.392 30 15 30s8.412 0 10.5-1.206a9 9 0 003.294-3.294C30 23.412 30 20.608 30 15s0-8.412-1.206-10.5A9 9 0 0025.5 1.206C23.412 0 20.608 0 15 0S6.588 0 4.5 1.206A9 9 0 001.206 4.5zM10.475 23H7.008V10.287h3.467V23zM8.74 8.822A2.031 2.031 0 016.72 6.783c0-1.125.906-2.038 2.022-2.038 1.117 0 2.023.913 2.023 2.038A2.03 2.03 0 018.74 8.822zM24.343 23h-3.467v-6.477c0-3.892-4.623-3.597-4.623 0V23h-3.467V10.287h3.467v2.04c1.614-2.988 8.09-3.21 8.09 2.862V23z"
							fill="currentColor"
						/>
					</svg>
				</Anchor>
			)}
		</div>
	)
}

export default SocialMediaGroup
