import { FunctionComponent } from "react"
import Anchor from "../anchor/anchor"
import SocialMediaGroup from "../socialMediaGroup"
import FooterLink from "./footerLink"

interface FooterProps {
	className?: string
	separatorClassName?: string
}

const Footer: FunctionComponent<FooterProps> = ({
	className,
	separatorClassName,
}) => {
	return (
		<div
			className={`flex flex-col px-5 w-full lg:max-w-5xl lg:px-0 self-center space-y-3 md:space-y-1.5 pt-6 md:pt-4 mb-3 font-semibold ${className}`}
		>
			<div className="flex flex-col md:flex-row justify-between space-y-2 md:space-y-0">
				<Anchor
					href="/"
					className="flex flex-row items-center font-semibold text-base justify-center"
				>
					<svg
						className="h-5 w-5 mr-2"
						viewBox="0 0 24 24"
						fill="currentColor"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M0 5.41463C0 7.97351 1.77503 10.1178 4.16097 10.6834C10.4006 12.2949 11.7053 13.5996 13.3168 19.8392C13.8824 22.2251 16.0267 24.0002 18.5855 24.0002C21.576 24.0002 24.0002 21.5759 24.0002 18.5855C24.0002 16.0188 22.2142 13.8692 19.8172 13.3116C13.6034 11.7045 12.2956 10.3968 10.6886 4.18294C10.131 1.78594 7.98136 0 5.41463 0C2.42422 0 0 2.42421 0 5.41463Z" />
						<path d="M13.3335 5.33333C13.3335 8.27885 15.7213 10.6667 18.6668 10.6667C21.6124 10.6667 24.0002 8.27885 24.0002 5.33333C24.0002 2.38781 21.6124 0 18.6668 0C15.7213 0 13.3335 2.38781 13.3335 5.33333Z" />
						<path d="M2.86102e-06 18.6666C2.86102e-06 21.6121 2.38782 23.9999 5.33334 23.9999C8.27885 23.9999 10.6667 21.6121 10.6667 18.6666C10.6667 15.7211 8.27885 13.3333 5.33334 13.3333C2.38782 13.3333 2.86102e-06 15.7211 2.86102e-06 18.6666Z" />
					</svg>
					SaySom | Digital networking spaces
				</Anchor>

				<SocialMediaGroup
					linkedIn="https://www.linkedin.com/company/saysom"
					instagram="https://instagram.com/SaySom.app"
					twitter="https://twitter.com/SaySomapp"
				/>
			</div>

			<div
				className={`h-px bg-black dark:bg-white opacity-20 ${
					separatorClassName ? separatorClassName : ""
				}`}
			/>

			<div className="flex flex-col md:flex-row justify-between space-y-2 md:space-y-0">
				<div className="flex flex-row space-x-4 justify-center">
					<FooterLink text="About" href="/about" />
					{/* <FooterLink text="Jobs" href="/about" /> */}
					<FooterLink text="Pricing" href="/pricing" />
					<FooterLink text="Blog" href="/blog" />
					<FooterLink text="Contact" href="/contact" />
					<FooterLink text="Newsletter" href="/newsletter" />
				</div>

				<div className="flex flex-row space-x-4 justify-center">
					<FooterLink text="Privacy policy" href="/privacy-policy" />
					<FooterLink text="Imprint" href="/imprint" />
				</div>
			</div>
		</div>
	)
}

export default Footer
