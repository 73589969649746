import styled from "styled-components"

export const Container = styled.div<{ height?: number | null }>`
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	min-height: ${(props) => (props.height ? props.height + "px" : "100vh")};
`

export const InnerContainer = styled.div<{ width?: string }>`
	width: ${(props) => (props.width ? props.width : "1024px")};
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	align-self: center;

	@media (max-width: ${(props) => (props.width ? props.width : "1024px")}) {
		padding: 0 20px;
		width: initial;
		align-self: stretch;
	}
`
