import { Cookies } from "react-cookie"
import { CookieSetOptions } from "universal-cookie"
import { GetState, SetState, StateCreator, StoreApi } from "zustand"
import { isRunningInBrowser } from "./persist"

const persistCookie = <T extends Record<string, unknown>>(
	config: StateCreator<T>
) => (set: SetState<T>, get: GetState<T>, api: StoreApi<T>): T => {
	const state = config(
		(payload) => {
			set(payload)

			if (isRunningInBrowser() && typeof payload === "object") {
				Object.entries(payload).forEach(([key, value]) => {
					const config: CookieSetOptions = {
						expires: new Date(2147483647000),
						path: "/",
						secure: process.env.NODE_ENV === "production",
						domain: process.env.NODE_ENV === "production" ? ".saysom.app" : "",
						sameSite: "lax",
					}

					// TODO: fix if year === 2038
					if (value === undefined) {
						new Cookies().remove(key, config)
					} else {
						new Cookies().set(key, value, config)
					}
				})
			}
		},
		get,
		api
	)

	return {
		...state,
		...(isRunningInBrowser() && new Cookies().getAll()),
	}
}

export default persistCookie
